import type { Wallet } from '../../types';
import { isAndroid } from '../../utils';
import TokenPocketLogo from '../logos/tokenpocket.svg';
import { InjectedConnector } from './injected';
import { getWalletConnectConnector } from './wallet-connect';
import type { WalletConnectOptions } from './wallet-connect-v2';

function getWalletInjectedProvider(): Window['ethereum'] {
    const isWallet = (ethereum: NonNullable<Window['ethereum']>) => {
        // Identify if Wallet injected provider is present.
        const wallet = !!ethereum.isTokenPocket;

        return wallet;
    };

    const injectedProviderExist = typeof window !== 'undefined' && typeof window.ethereum !== 'undefined';

    // No injected providers exist.
    if (!injectedProviderExist) {
        return;
    }

    // Trust Wallet was injected into window.ethereum.
    if (isWallet(window.ethereum!)) {
        return window.ethereum;
    }

    // Wallet provider might be replaced by another
    // injected provider, check the providers array.
    if (window.ethereum?.providers) {
        // ethereum.providers array is a non-standard way to
        // preserve multiple injected providers. Eventually, EIP-5749
        // will become a living standard and we will have to update this.
        return window.ethereum.providers.find(isWallet);
    }
}

export const tokenpocket = (options: Omit<WalletConnectOptions, 'chains'>): Wallet => ({
    id: 'tokenpocket',
    name: 'Token Pocket',
    iconUrl: TokenPocketLogo,
    downloadUrls: {
        qrCode: 'https://www.tokenpocket.pro/en/download/app',
        browserExtension: 'https://www.tokenpocket.pro/en/download/app',
    },
    createConnector: (chains) => {
        const provider = getWalletInjectedProvider();
        const isWalletInjected = Boolean(provider);
        const shouldUseWalletConnect = !isWalletInjected;

        const evmChains = chains?.filter((chain) => chain.name.toLowerCase() !== 'solana');

        const connector = shouldUseWalletConnect
            ? getWalletConnectConnector('tokenpocket', options, chains)
            : new InjectedConnector({ chains: evmChains, getProvider: () => provider });

        const getAppLinkUri = () => {
            return isAndroid() ? undefined : 'tpoutside://wc';
        };

        return {
            connector,
            mobile: {
                getAppLinkUri: shouldUseWalletConnect ? getAppLinkUri : undefined,
            },
        };
    },
});
