import type { Wallet } from '../../types';
import { isAndroid } from '../../utils';
import TrustLogo from '../logos/trust.svg';
import { InjectedConnector } from './injected';
import { getWalletConnectConnector } from './wallet-connect';
import type { WalletConnectOptions } from './wallet-connect-v2';

function getTrustWalletInjectedProvider(): Window['ethereum'] {
    const isTrustWallet = (ethereum: NonNullable<Window['ethereum']>) => {
        // Identify if Trust Wallet injected provider is present.
        const trustWallet = !!ethereum.isTrust;

        return trustWallet;
    };

    const injectedProviderExist = typeof window !== 'undefined' && typeof window.ethereum !== 'undefined';

    // No injected providers exist.
    if (!injectedProviderExist) {
        return;
    }

    // Trust Wallet injected provider is available in the global scope.
    // There are cases that some cases injected providers can replace window.ethereum
    // without updating the ethereum.providers array. To prevent issues where
    // the TW connector does not recognize the provider when TW extension is installed,
    // we begin our checks by relying on TW's global object.
    if (window['trustwallet']) {
        return window['trustwallet'];
    }

    // Trust Wallet was injected into window.ethereum.
    if (isTrustWallet(window.ethereum!)) {
        return window.ethereum;
    }

    // Trust Wallet provider might be replaced by another
    // injected provider, check the providers array.
    if (window.ethereum?.providers) {
        // ethereum.providers array is a non-standard way to
        // preserve multiple injected providers. Eventually, EIP-5749
        // will become a living standard and we will have to update this.
        return window.ethereum.providers.find(isTrustWallet);
    }
}

export const trust = (options: Omit<WalletConnectOptions, 'chains'>): Wallet => ({
    id: 'trust',
    name: 'Trust',
    iconUrl: TrustLogo,
    downloadUrls: {
        qrCode: 'https://trustwallet.com/download',
        browserExtension: 'https://trustwallet.com/browser-extension',
    },
    createConnector: (chains) => {
        const trustProvider = getTrustWalletInjectedProvider();
        const isTrustWalletInjected = Boolean(trustProvider);
        const shouldUseWalletConnect = !isTrustWalletInjected;

        const evmChains = chains?.filter((chain) => chain.name.toLowerCase() !== 'solana');

        const connector = shouldUseWalletConnect
            ? getWalletConnectConnector('trust', options, chains)
            : new InjectedConnector({ chains: evmChains, getProvider: () => trustProvider });

        const getAppLinkUri = () => {
            return isAndroid() ? undefined : 'trust://wc';
        };

        return {
            connector,
            mobile: {
                getAppLinkUri: shouldUseWalletConnect ? getAppLinkUri : undefined,
            },
        };
    },
});
