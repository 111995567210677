import { rpcUrl } from '@particle-network/auth';

export function getEVMRpcUrl(chainId: number | string): string {
    if (typeof window === 'undefined' || !window.particle) {
        return '';
    }
    return `${rpcUrl()}/evm-chain?chainId=${Number(chainId)}&projectUuid=${
        window.particle.auth.config.projectId
    }&projectKey=${window.particle.auth.config.clientKey}`;
}

export function getSolanaRpcUrl(chainId: number | string): string {
    if (typeof window === 'undefined' || !window.particle) {
        return '';
    }
    return `${rpcUrl()}/solana?chainId=${Number(chainId)}&projectUuid=${
        window.particle.auth.config.projectId
    }&projectKey=${window.particle.auth.config.clientKey}`;
}
