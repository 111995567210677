import {
    ConnectorNotFoundError,
    type PublicKey,
    type SendOptions,
    type SolanaProvider,
    type Wallet,
} from '../../types';
import PhantomLogo from '../logos/phantom.png';
import { SolanaConnector } from './base';

class WrapPhantomSolanaProvider implements SolanaProvider {
    #solana;
    constructor(solana: any) {
        this.#solana = solana;
    }
    get publicKey(): PublicKey | null {
        return this.#solana.publicKey;
    }
    on(event: string, listener: any): void {
        this.#solana.on(event, listener);
    }
    once(event: string, listener: any): void {
        this.#solana.once(event, listener);
    }
    off(event: string, listener: any): void {
        this.#solana.off(event, listener);
    }
    removeListener(event: string, listener: any): void {
        this.#solana.removeListener(event, listener);
    }
    connect(): Promise<void> {
        return this.#solana.connect();
    }
    disconnect(): Promise<void> {
        return this.#solana.disconnect();
    }
    async signAndSendTransaction(transaction: unknown, options?: SendOptions | undefined): Promise<string> {
        return (await this.#solana.signAndSendTransaction(transaction, options)).signature;
    }
    signTransaction(transaction: unknown): Promise<any> {
        return this.#solana.signTransaction(transaction);
    }
    signAllTransactions(transaction: unknown[]): Promise<any[]> {
        return this.#solana.signAllTransactions(transaction);
    }
    async signMessage(message: Uint8Array): Promise<Uint8Array> {
        return (await this.#solana.signMessage(message)).signature;
    }
}

export class PhantomConnector extends SolanaConnector {
    private phantomProvider?: any;
    private isConnected?: boolean;

    constructor() {
        super();
        this.isConnected = false;
    }

    async connect(): Promise<SolanaProvider> {
        const provider = await this.getProvider(); // see "Detecting the Provider"
        if (this.isConnected && this.phantomProvider) return this.phantomProvider;
        if (!provider) throw new ConnectorNotFoundError();
        try {
            // this.emit('message', { type: 'connecting' });
            if (provider.on) {
                //https://docs.phantom.app/solana/integrating-phantom/extension-and-in-app-browser-web-apps/establishing-a-connection#changing-accounts
                provider.off('accountChanged', this.onAccountsChanged);
                provider.on('accountChanged', this.onAccountsChanged);
                provider.off('disconnect', this.onDisconnect);
                provider.on('disconnect', this.onDisconnect);
            }
            await provider.connect();
            if (!provider.publicKey) throw new Error('Connect Fail is publicKey not found');
        } catch (err) {
            // { code: 4001, message: 'User rejected the request.' }
            console.error('phantom request connect error', err);
            throw new Error('User rejected the request.');
        }
        this.isConnected = true;
        this.phantomProvider = new WrapPhantomSolanaProvider(provider);
        return this.phantomProvider;
    }

    async disconnect(): Promise<void> {
        this.isConnected = false;
        if (this.phantomProvider) {
            this.phantomProvider.off('accountChanged', this.onAccountsChanged);
            this.phantomProvider.off('disconnect', this.onDisconnect);
            await this.phantomProvider.disconnect();
            this.phantomProvider = undefined;
        }
    }

    async getProvider() {
        if (this.phantomProvider) return this.phantomProvider;
        if ('phantom' in window) {
            // @ts-ignore
            const provider = window.phantom?.solana;
            if (provider?.isPhantom) {
                return provider;
            }
        }
        return undefined;
    }

    private onAccountsChanged = (publicKey: any) => {
        if (publicKey) {
            // Set new public key and continue as usual
            console.log(`Switched to account ${publicKey.toBase58()}`);
            this.emit('accountsChanged', [publicKey.toBase58()]);
        }
    };

    private onDisconnect = () => {
        console.log('phantom on disconnect');
        this.provider = undefined;
        this.emit('disconnect');
    };
}

export const phantom = (): Wallet => {
    return {
        id: 'phantom',
        name: 'Phantom',
        iconUrl: PhantomLogo,
        downloadUrls: {
            browserExtension: 'https://phantom.app/download',
            qrCode: 'https://phantom.app/download',
        },
        createConnector: () => {
            const connector = new PhantomConnector();
            return {
                connector,
                mobile: {
                    getUri: undefined,
                },
                qrCode: {
                    getUri: undefined,
                },
            };
        },
    };
};
