import type { CoinbaseWalletSDK } from '@coinbase/wallet-sdk';
import type { CoinbaseWalletSDKOptions } from '@coinbase/wallet-sdk/dist/CoinbaseWalletSDK';
import type { EVMProvider, Wallet } from '../../types';
import { UserRejectedRequestError } from '../../types';
import CoinbaseLogo from '../logos/coinbase.svg';
import { EVMConnector } from './base';

type Options = Omit<CoinbaseWalletSDKOptions, 'reloadOnDisconnect'> & {
    /**
     * Fallback Ethereum JSON RPC URL
     * @default ""
     */
    jsonRpcUrl?: string;
    /**
     * Fallback Ethereum Chain ID
     * @default 1
     */
    chainId?: number;
    /**
     * Whether or not to reload dapp automatically after disconnect.
     */
    reloadOnDisconnect?: boolean;
};

export default class CoinbaseConnector extends EVMConnector {
    #client?: CoinbaseWalletSDK;

    constructor(private options?: Options) {
        super(options);
    }

    async connect(): Promise<EVMProvider> {
        try {
            const provider = await this.getProvider();
            provider.on('accountsChanged', this.onAccountsChanged);
            provider.on('chainChanged', this.onChainChanged);
            provider.on('disconnect', this.onDisconnect);

            this.emit('message', { type: 'connecting' });
            await provider?.enable?.();
            return provider;
        } catch (error) {
            if (/(user closed modal|accounts received is empty)/i.test((error as Error).message))
                throw new UserRejectedRequestError(error as Error);
            throw error;
        }
    }
    async disconnect() {
        if (!this.provider) return;

        const provider = await this.getProvider();
        provider.removeListener('accountsChanged', this.onAccountsChanged);
        provider.removeListener('chainChanged', this.onChainChanged);
        provider.removeListener('disconnect', this.onDisconnect);
        await provider.disconnect?.();
        (provider as any)?.close();
    }

    async getProvider(): Promise<EVMProvider> {
        if (!this.provider) {
            let CoinbaseWalletSDK = (await import('@coinbase/wallet-sdk')).default;
            // Workaround for Vite dev import errors
            // https://github.com/vitejs/vite/issues/7112
            if (
                typeof CoinbaseWalletSDK !== 'function' &&
                // @ts-expect-error This import error is not visible to TypeScript
                typeof CoinbaseWalletSDK.default === 'function'
            )
                CoinbaseWalletSDK = (CoinbaseWalletSDK as unknown as { default: typeof CoinbaseWalletSDK }).default;
            this.#client = new CoinbaseWalletSDK(this.options ?? ({} as any));
            this.provider = this.#client.makeWeb3Provider(this.options?.jsonRpcUrl, this.options?.chainId) as any;
        }
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion, @typescript-eslint/no-extra-non-null-assertion
        return this.provider!!;
    }

    private onAccountsChanged = (accounts: string[]) => {
        if (accounts.length === 0) {
            this.provider = undefined;
        }
        this.emit('accountsChanged', accounts);
    };

    private onChainChanged = (chainId: number | string) => {
        this.emit('chainChanged', `0x${Number(chainId).toString(16)}`);
    };

    private onDisconnect = () => {
        if (this.provider) {
            this.provider = undefined;
            this.emit('disconnect');
        }
    };
}

export const coinbase = (options?: Options): Wallet => ({
    id: 'coinbase',
    name: 'Coinbase',
    iconUrl: CoinbaseLogo,
    createConnector: () => {
        const connector = new CoinbaseConnector(options);
        //@ts-ignore
        const getUri = async () => (await connector.getProvider()).qrUrl;
        return {
            connector,
            mobile: {
                getUri,
            },
            qrCode: {
                //@ts-ignore
                getUri,
            },
        };
    },
});
